/* Overall layout for the header */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0D0D0D; /* Black background */
    padding: 20px;
    height: 100vh;
    color: #fff;
    margin: 0; /* Remove any default margin */
    padding-top: 120px; /* Add padding to push content away from the navbar */
}

/* Ensure that both header and overview use the same background color */
body {
    background-color: #0D0D0D; /* Black background for the entire page */
    margin: 0; /* Remove body margin */
    padding: 0; /* Remove body padding */
}

/* Ensure no margin between sections */
header, .overview-section {
    margin: 0;
    padding: 0;
}

/* Overview Section */
.overview-section {
    padding: 50px 20px;
    background-color: #0D0D0D; /* Same black background */
    color: #fff;
    text-align: center;
    margin-top: 0; /* Remove margin between header and overview */
}

.overview-title {
    font-size: 3rem;
    font-weight: bold;
    color: #fff; /* Golden color */
    margin-bottom: 20px;
}

.overview-text {
    font-size: 1.5rem;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    color: #bdbdbd; /* Light grey for text */
}

/* Header content adjustments */
.header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
    text-align: center;
    margin-bottom: 30px; /* Add space between content and image */
}

.greeting-text {
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
    margin-left: 0; /* Remove the large margin-left */
    margin-bottom: 20px;
    margin-top: 200px; /* Ensure greeting text has space from the top */

    
}

.name-highlight {
    color: #D32F2F; /* Highlight color in red */
    transition: all 0.4s ease; /* Smooth transition for hover effect */
}

.name-highlight:hover {
    color: #e65212; /* New color when hovering (gold) */
    font-size: 5.5rem; /* Slight increase in font size */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Adds shadow to make it pop */
}

.contact-btn {
    background-color: #D32F2F;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.5rem;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
}

.contact-btn:hover {
    background-color: #B71C1C; /* Darker red for hover effect */
}

/* Adjust container to center the image */
.image-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Full height of the viewport */
    width: 40%;
}

/* Adjust the profile photo styling */
.profile-photo {
    width: 70%;
    height: auto;
    border-radius: 400px;
    object-fit: cover;
    max-width: 700px; /* Ensure the image scales well */
}

/* --- Responsive adjustments --- */

/* Tablet Styles (max-width: 1024px) */
@media (max-width: 1024px) {
    .header-container {
        flex-direction: column;
        text-align: center;
        padding-top: 150px; /* Add more space for tablets */
    }

    .greeting-text {
        font-size: 2.5rem; /* Adjust font size for tablets */
        margin-top: 50px; /* Extra margin for space from the top */
    }



    /* Media query for screens smaller than 768px (commonly mobile) */
@media (max-width: 768px) {
    .greeting {
      margin-top: 100px; /* Increase the top margin to lower the text */
      font-size: 20px; /* Optional: Adjust font size if necessary */
    }
  }


  
    .profile-photo {
        max-width: 200px; /* Reduce image size for tablets */
        margin-top: 30px;
    }

    .contact-btn {
        font-size: 1.0rem;
        padding: 12px 25px;
    }
}

/* Mobile Styles (max-width: 768px) */
@media (max-width: 768px) {
    .header-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 10px;
        padding-top: 180px; /* Keep this value for spacing */
    }

    .greeting-text {
        font-size: 2.5rem; /* Keep this value for font size */
        margin-top: 200px; /* Increase this to push the greeting down further */
    }

    .profile-photo {
        max-width: 200px; /* Smaller image for mobile */
        margin-top: 20px;
    }

    .contact-btn {
        font-size: 1.2rem;
        padding: 10px 20px;
    }
}

