/* Base App styles */
.App {
  text-align: center;
}

/* Header and content adjustments for tablets and mobile */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); /* Dynamic font size based on viewport */
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Logo animation */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Logo animation settings */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Keyframes for logo spin */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Global font size */
body {
  font-size: 16px;
}

/* --- Media Queries for Responsiveness --- */

/* Tablet Styles (max-width: 1024px) */
@media (max-width: 1024px) {
  .App-header {
    font-size: calc(9px + 2vmin); /* Adjust font size for tablet screens */
    padding: 20px;
  }

  .App-logo {
    height: 30vmin; /* Smaller logo size on tablets */
  }

  body {
    font-size: 14px; /* Slightly smaller font size for tablet */
  }
}

/* Mobile Styles (max-width: 768px) */
@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin); /* Further adjust font size for mobile */
    padding: 15px;
  }

  .App-logo {
    height: 20vmin; /* Smaller logo size on mobile */
  }

  body {
    font-size: 13px; /* Even smaller font size for mobile */
  }

  .App-link {
    font-size: 1.2rem; /* Adjust link size for mobile */
  }
}
