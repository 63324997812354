.contact-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0d0d0d; /* Black background */
  padding: 60px;
  color: #fff; /* White text */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.contact-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%; /* Take half of the space */
}

.contact-title {
  font-size: 4rem; /* Reduce the size slightly */
  font-family: 'Georgia', serif;
  margin-bottom: 50px;
  font-weight: 300;
}

.contact-icons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.contact-icon {
  width: 100px; /* Set a uniform width */
  height: 100px; /* Set a uniform height */
  cursor: pointer;
  transition: transform 0.3s;
  object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
  border-radius: 50%; /* Makes all icons circular */
  background-color: #fff; /* Add a background to prevent uneven borders */
  padding: 10px; /* Add padding to ensure the image fits well */
}

.contact-icon:hover {
  transform: scale(1.1);
}

.contact-info {
  text-align: left;
  margin-top: 20px;
}

.label {
  margin-bottom: 5px;
  font-size: 2rem;
  font-weight: 300;
}

.value {
  font-size: 1.3rem;
  margin-bottom: 15px;
}

.contact-info a {
  color: #fff;
  text-decoration: none;
  font-family: 'Playfair Display', serif;
  font-size: 1.8rem;
  margin-left: 10px;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-right {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40%; /* Take up 40% of the space */
}

.profile-pic {
  width: 350px;
  height: auto;
  border-radius: 50%;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* --- Responsive adjustments --- */

/* Tablet Styles (max-width: 1024px) */
@media (max-width: 1024px) {
  .contact-section {
      flex-direction: column;
      padding: 40px 20px;
  }

  .contact-left {
      max-width: 100%; /* Full width on tablets */
      text-align: center;
      margin-bottom: 20px;
  }

  .profile-pic {
      max-width: 250px; /* Smaller image for tablets */
  }

  .contact-title {
      font-size: 3.5rem;
  }

  .label, .value {
      font-size: 1.8rem;
  }

  .contact-info a {
      font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .contact-section {
      flex-direction: column;
      align-items: center;
      padding: 20px;
  }

  .contact-left {
      max-width: 100%;
      margin-bottom: 30px;
      text-align: center;
  }

  .profile-pic {
      max-width: 200px; /* Smaller image for mobile */
  }

  .contact-title {
      font-size: 2.5rem; /* Reduce title size for mobile */
  }

  .label, .value {
      font-size: 1.5rem; /* Smaller text for mobile */
  }

  .contact-info a {
      font-size: 1.4rem;
  }
}
