.experience-education-section {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    background-color: #0d0d0d;
    color: #FFFF;
    padding: 40px;
  }
  
  .experience, .education {
    width: 45%;
    margin-bottom: 20px;

  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    font-family: 'Times New Roman', Times, serif;
    color: #FFFF;
  }
  
  ul {
    font-size: 1.8rem; /* Slightly increase font size */
    font-family: 'Georgia', serif; /* Change font to a more elegant style */
    line-height: 1.8; /* Increase line height for better readability */
    max-width: 900px;
    margin: 0 auto;
    color: #E0E0E0; /* Softer light grey */
    padding: 20px; /* Padding around the text block */
    border-radius: 5px; /* Add rounded corners */
    box-shadow: 0px 4px 10px #0D0D0D; /* Add a slight shadow for text block */
    transition: transform 0.3s ease-in-out;
  }
  
  li {
    margin-bottom: 40px;
  }
  
  strong {
    font-size: 1.5rem;
  }
  
  p {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #FFFF;

  }

  /* Add this to ExpEdu.css */

@media (max-width: 1024px) {
  .experience-education-section {
    flex-direction: column;
    align-items: center;
  }

  .experience, .education {
    width: 90%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .experience-education-section {
    flex-direction: column;
    align-items: center;
  }

  .experience, .education {
    width: 100%;
    text-align: center;
  }
}
