/* Projects section styling */
.projects-section {
    background-color: #0D0D0D; /* Dark background */
    color: #fff;
    padding: 60px 20px;
    text-align: center;
  }
  
  .projects-title {
    font-size: 3.0rem; /* Slightly larger font size */
    font-weight: bold; /* Maintain strong emphasis */
    color: #E0E0E0; /* Soft white-grey color */
    margin-bottom: 30px;
    text-transform: capitalize; /* Use capital letters for each word */
    letter-spacing: 1px; /* Moderate letter spacing */
    text-shadow: 1px 1px 3px #0D0D0D; /* Soft shadow for subtle depth */
    background: none; /* Remove gradient for a cleaner look */
    color: #E0E0E0; /* Use a golden color for elegance */
  }
  
  .projects-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .project-card {
    background-color: #1A1A1A;
    padding: 20px;
    border-radius: 15px;
    max-width: 400px;
    margin: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
  }
  
  .project-card:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  .project-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .project-image {
    width: 100%;
    height: auto;
  }
  
  .project-title {
    font-size: 2rem;
    margin-top: 15px;
  }
  
  .project-description {
    font-size: 1.2rem;
    color: #bdbdbd;
    margin-top: 10px;
  }
  
  .project-tags span {
    display: inline-block;
    background-color: #ffff; /* Golden color */
    color: #0D0D0D;
    padding: 5px 10px;
    margin-right: 5px;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 0.9rem;
  }
/* Button container flexbox styling */
.button-container {
  display: flex;
  justify-content: flex-start; /* Aligns the button to the left */
}

.github-btn {
  background-color: #D32F2F; /* Red background */
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 50px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* Cybersecurity certificate image styling */
.CybersecurityCertif {
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.CybersecurityCertif:hover {
  background-color: #B71C1C; /* Darker red for hover effect */
}

/* Cybersecurity button styling (same as GitHub button) */
.certificate-btn {
  background-color: #D32F2F; /* Red background */
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 90px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.certificate:hover {
  background-color: #B71C1C; /* Darker red for hover effect */
}

/* Add this to Projects.css */

@media (max-width: 1024px) {
  .projects-container {
    flex-direction: column;
    align-items: center;
  }

  .project-card {
    max-width: 90%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .projects-container {
    flex-direction: column;
    align-items: center;
  }

  .project-card {
    max-width: 90%;
    margin-bottom: 20px;
  }
}
