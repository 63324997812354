/* Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0D0D0D;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.navbar-brand h1 {
  color: #ffffff;
  font-size: 1.5rem;
  margin: 0;
}

.navbar-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  font-size: 1.0rem;
}

.navbar-links li {
  margin-right: 20px;
}

.navbar-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.0rem;
}

.navbar-links a:hover {
  color: red;
  cursor: pointer;
}

/* Dropdown Menu Styling */
.nav-dropdown {
  position: relative; /* Ensure the dropdown is positioned relative to this element */
}

.dropdown-toggle {
  cursor: pointer;
  color: #ffffff;
}

/* Align dropdown directly below the toggle */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Position it just below the toggle */
  left: 0; /* Align with the left edge of the toggle */
  background-color: #0D0D0D;
  padding: 10px 0;
  width: 200px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  
}

.dropdown-menu li {
  padding: 10px 20px;
}

.dropdown-menu a {
  color: #ffffff;
  text-decoration: none;
  display: block;
  
}

.dropdown-menu a:hover {
  background-color: #333;
}

.dropdown-menu li:hover {
  background-color: #333;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%); /* This centers the dropdown */
  background-color: #0D0D0D;
  padding: 10px 0;
  width: 200px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  flex-direction: column; 
  
}