/* Footer Styling */
.footer {
    background-color: #0d0d0d; /* Dark background */
    color: #ffffff; /* White text */
    padding: 20px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .footer-content p {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icons a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: red; /* Red hover effect */
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  }
  