/* Global Styles */
body {
  margin: 0;
  font-size: 1rem; /* Base font size is 16px */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Font Styles for Headings */
h1 {
  font-size: 2rem; /* Adjust as needed */
}

h2 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
}

/* --- Responsive Styling for Tablets (max-width: 1024px) --- */
@media (max-width: 1024px) {
  body {
    font-size: 15px; /* Slightly smaller font size on tablets */
  }

  h1 {
    font-size: 1.8rem; /* Slightly reduce heading size */
  }

  h2 {
    font-size: 1.4rem; /* Adjust h2 for tablets */
  }

  p {
    font-size: 0.95rem; /* Adjust paragraph size for tablets */
  }

  .container {
    padding: 15px;
    width: 90vw; /* Slightly less width for tablets */
  }

  .item {
    margin-bottom: 20px;
    width: 90%;
    height: auto;
  }
}

/* --- Mobile Styles: For screens smaller than 768px --- */
@media (max-width: 768px) {
  body {
    font-size: 14px; /* Smaller font size for mobile */
  }

  h1 {
    font-size: 1.5rem; /* Reduce size of h1 for mobile */
  }

  h2 {
    font-size: 1.2rem; /* Reduce size of h2 for mobile */
  }

  p {
    font-size: 0.9rem; /* Slightly smaller paragraph text for mobile */
  }

  .container {
    padding: 10px;
    width: 100vw; /* Full width for mobile */
  }

  .item {
    margin-bottom: 15px;
    width: 100%; /* Ensure full width on mobile screens */
    height: auto; /* Maintain aspect ratio for images/videos */
  }
}


/* Navbar container */
 nav{
  display: flex;
  justify-content: space-between; /* Ensure items are spread out */
  align-items: center; /* Center vertically */
  width: 100%; /* Make sure the navbar takes up the full width */
  padding: 10px 20px; /* Adjust padding */
  overflow: hidden; /* Prevent content from overflowing */
}

/* Navbar list items (horizontal layout) */
nav ul {
  display: flex;
  justify-content: space-around; /* Space out items evenly */
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-right: 15px; /* Adjust space between items */
}

/* Links in the navbar */
nav ul li a {
  text-decoration: none;
  font-size: 16px; /* Adjust font size if needed */
}

/* Adjustments for mobile screens */
@media (max-width: 480px) {
  nav ul li {
      margin-right: 10px; /* Reduce space between items on small screens */
  }

  nav ul li a {
      font-size: 10px; /* Reduce font size on mobile */
  }

  nav {
      padding: 5px 10px; /* Reduce padding on mobile */
  }
}
